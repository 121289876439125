import { Injectable } from '@angular/core';
import { Trade } from '../_models/trade';
import { HttpClient, HttpErrorResponse, HttpResponse  } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { catchError, shareReplay } from 'rxjs/operators';
import { saveAs } from 'file-saver'; 
import { throwError } from 'rxjs';
const baseUrl = `${environment.apiUrl}/orders`;
@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(private http: HttpClient) { }

  registerOrder(payload:any){
    return this.http.post(`${baseUrl}/register-order`,payload)
  }
  reOrder(payload:any){
    console.log(payload);
    return this.http.put(`${baseUrl}/update-order?tradeID=${payload['tradeID']}`, payload)
  }

  updateOrder(tradeId:any,payload:any){
    return this.http.put(`${baseUrl}/update-order?tradeID=${tradeId}`,payload);
  }

  confirmOrder(tradeId:any){
    return this.http.put(`${baseUrl}/confirm-order?tradeID=${tradeId}`,{});
  }
  confirmOrderAble(tradeId:any){
    return this.http.put(`${baseUrl}/confirm-orderableace?tradeID=${tradeId}`,{});
  }
  confirmOrderDmcc(tradeId:any){
    return this.http.put(`${baseUrl}/confirm-orderdmcc?tradeID=${tradeId}`,{});
  }

  getOrderDetails(tradeID:any){
    return this.http.get<any[]>(`${baseUrl}/get-orders-details?tradeID=${tradeID}`);
  }

  getOrderStatus(tradeID:any){
    return this.http.put<any[]>(`${baseUrl}/get-order-status?tradeID=${tradeID}`,{}).pipe(shareReplay());
  }
  getOrderStatusableace(tradeID:any){
    return this.http.put<any[]>(`${baseUrl}/get-order-statusableace?tradeID=${tradeID}`,{}).pipe(shareReplay());
  }
  getOrderStatusdmcc(tradeID:any){
    return this.http.put<any[]>(`${baseUrl}/get-order-statusdmcc?tradeID=${tradeID}`,{}).pipe(shareReplay());
  }

  uploadPurchaseConfirmation(tradeId:any, file:any){
    const formData = new FormData();
    formData.append('file', file);  
    return this.http.put(`${baseUrl}/upload-purchaseconfirmation?tradeID=${tradeId}`, formData);
  }

  uploadSaleConfirmation(tradeId:any, file:any){
    const formData = new FormData();
    formData.append('file', file);  
    return this.http.put(`${baseUrl}/upload-saleconfirmation?tradeID=${tradeId}`, formData);
  }

  uploadOfferLetter(tradeId:any, file:any){
    const formData = new FormData();
    formData.append('file', file);  
    return this.http.put(`${baseUrl}/upload-offetletter?tradeID=${tradeId}`, formData);
  }

  uploadSaleInstruction(tradeId:any, file:any){
    const formData = new FormData();
    formData.append('file', file);  
    return this.http.put(`${baseUrl}/upload-saleinstruction?tradeID=${tradeId}`, formData);
  }
  uploadCertificate3(tradeId:any, file:any){
    const formData = new FormData();
    formData.append('file', file);  
    return this.http.put(`${baseUrl}/upload-certificate3?tradeID=${tradeId}`, formData);
  }
  doenloadcertificate(blobname: string) {
  
    this.http.get(`${baseUrl}/doenloadcertificate?blobname=${blobname}`, {
      responseType: 'blob',
      observe: 'response'
    }).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error('Error Response:', error);
        if (error.status === 404) {
          console.error('Blob not found:', error.message);
        } else if (error.status === 500) {
          console.error('Server error:', error.message);
        } else {
          console.error('Unexpected error:', error.message);
        }
        return throwError(error); // Re-throw the error after logging
      })
    ).subscribe(
      (response: HttpResponse<Blob>) => {
        if(response.body.size === 115 || response.body.size <= 1500){
          console.log("not pdf");
          window.open(blobname)
        }else{
          saveAs(response.body, blobname);
          
        }
      },
      (error: HttpErrorResponse) => {
        // You can add further error handling if needed
      }
    );
  }
  
  
  // doenloadcertificate(blobname:any){ 
  // console.log(baseUrl)
  // console.log(blobname)
  //   this.http.get(`${baseUrl}/doenloadcertificate?blobname=${blobname}`, { responseType: 'blob' }).subscribe(
  //     (response: Blob) => {
  //       console.log(response);
  //       // Save the blob as a file using file-saver.js
  //       saveAs(response, blobname);
  //     },
  //     (error) => {
  //       console.error('Error downloading file:', error);
  //       return error;
  //     }
  //   );
  //   //return this.http.get(`${baseUrl}/doenloadcertificate?blobname=${blobname}`);
  // }



}
