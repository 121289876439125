import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AccountService, AlertService } from '../../_services';
import { MustMatch } from '../../_helpers';
import { DataService } from 'src/app/_services/data.service';
import { buyOrder, clientTrade } from 'src/app/_models/trade';
import { OrderService } from 'src/app/_services/order.service';
import { MatSnackBarComponent } from 'src/app/shared/components/mat-snack-bar/mat-snack-bar.component';
import { TradeService } from 'src/app/_services/trade.service';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { APP_DATE_FORMATS, AppDateAdapter } from 'src/app/shared/date.adapter';
import moment from 'moment';
import { BankService } from 'src/app/_services/bank.service';
import { OrbitService } from 'src/app/_services/orbit.service';

interface productType {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-new-order-dmcc',
  templateUrl: './new-order-dmcc.component.html',
  styleUrls: ['./new-order-dmcc.component.less'],
  providers: [
    {
        provide: DateAdapter, useClass: AppDateAdapter
    },
    {
        provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS
    }
    ]
})
export class NewOrderDmccComponent implements OnInit,OnDestroy {
  prodTypes: productType[] = [
    {value: 'Commodity Murabaha', viewValue: 'Commodity Murabaha'}
  ];
  commdityTypes: productType[] = [
    {value: '1', viewValue: 'Principal'},
    {value: '2', viewValue: ' Asagentfor'}
  ];
  orderTypes:productType[]=[
    {value: '1', viewValue: 'Bulk'},
    {value: '2', viewValue: 'Multiple'},
    {value: '3', viewValue: 'Module A'}
  ];
  orderTenor:productType[]=[
    {value: 'O/N', viewValue: 'Over Night'},
    {value: 'OTHERS', viewValue: 'OTHERS'}
  ];
  OtherTenorUnit:productType[]=[
    {value: 'Days', viewValue: 'DAY'},
    {value: 'Weeks', viewValue: 'WEEK'},
    {value: 'Months', viewValue: 'MONTH'},
    {value: 'Years', viewValue: 'YEAR'}
  ];
  OtherTenor =[
    {value:1,viewValue: 1, category:['DAY','WEEK','MONTH','YEAR']},
    {value:2,viewValue: 2, category:['DAY','WEEK','MONTH','YEAR']},
    {value:3,viewValue: 3, category:['DAY','WEEK','MONTH','YEAR']},
    {value:4,viewValue: 4, category:['DAY','WEEK','MONTH','YEAR']},
    {value:5,viewValue: 5, category:['DAY','WEEK','MONTH','YEAR']},
    {value:6,viewValue: 6, category:['DAY','WEEK','MONTH']},
    {value:7,viewValue: 7, category:['DAY','WEEK','MONTH']},
    {value:8,viewValue: 8, category:['DAY','WEEK','MONTH']},
    {value:9,viewValue: 9, category:['DAY','WEEK','MONTH']},
    {value:10,viewValue: 10, category:['DAY','WEEK','MONTH']},
    {value:11,viewValue: 11, category:['DAY','WEEK','MONTH']},
    {value:12,viewValue: 12, category:['DAY','WEEK','MONTH']},
    {value:13,viewValue: 13, category:['DAY']},
    {value:14,viewValue: 14, category:['DAY']},
    {value:15,viewValue: 15, category:['DAY']},
    {value:16,viewValue: 16, category:['DAY']},
    {value:17,viewValue: 17, category:['DAY']},
    {value:18,viewValue: 18, category:['DAY']},
    {value:19,viewValue: 19, category:['DAY']},
    {value:20,viewValue: 10, category:['DAY']},
    {value:21,viewValue: 21, category:['DAY']},
    {value:22,viewValue: 22, category:['DAY']},
    {value:23,viewValue: 23, category:['DAY']},
    {value:24,viewValue: 24, category:['DAY']},
    {value:25,viewValue: 25, category:['DAY']},
    {value:26,viewValue: 26, category:['DAY']},
    {value:27,viewValue: 27, category:['DAY']},
    {value:28,viewValue: 28, category:['DAY']},
    {value:29,viewValue: 29, category:['DAY']},
    {value:30,viewValue: 30, category:['DAY']}
   
  ]
  form: UntypedFormGroup;
  id: string;
  isAddMode: boolean;
  loading = false;
  submitted = false;
  newOrder:clientTrade;
  accounts: any[];
  orbit: any[];
  account = this.accountService.accountValue;
  isMurabahaConfirmed = false;
  isSaleConfirmed = false;
  showBuySell = false;
  orderInfo:buyOrder;
  isBank =false;
  isOrbit =false;
  banks :any[];
  brokerid :any[];
  enableResubmit = false;
  constructor(
      private formBuilder: UntypedFormBuilder,
      private route: ActivatedRoute,
      private router: Router,
      private accountService: AccountService,
      private alertService: AlertService,
      private dataService:DataService,
      private orderService:OrderService,
      private snackBar: MatSnackBarComponent,
      private tradeService:TradeService,
      private bankService:BankService,
      private orbitService:OrbitService
  ) {}

  ngOnInit() {
    this.getAllBanks();
    this.dataService.getNewUserInfo().subscribe(info => {
      this.newOrder = info['tradeObj'];   
      this.brokerid = info['brokerid'];   
      this.isBank = info['type'] ==='BankTrade' ? true :false;
    });
    
    this.dataService.getMurabahaConfirm().subscribe((isConfirmed) =>{
      this.isMurabahaConfirmed = isConfirmed['isConfirmed'];
    });
    this.dataService.getSaleConfirm().subscribe((isConfirmed) =>{
      this.isSaleConfirmed = isConfirmed['isConfirmed'];
    });
    this.dataService.getTradeObj().subscribe((tradeObj) =>{
      this.orderInfo = tradeObj['tradeObj'];
    });
    this.accountService.getAll()
    .pipe(first())
    .subscribe(accounts => {
      this.accounts = accounts;
      this.fillForm();      
    }
      );
      this.orbitService.getAllorbitclients().pipe(first())
      .subscribe(orbit => {
        this.orbit = orbit;
        this.fillForm();
      });

      this.id = this.route.snapshot.params['id'];
      this.isAddMode = !this.id;

      this.form = this.formBuilder.group({
        clientName: ['', Validators.required],
        clientName2: ['', Validators.required],
        currency: ['', Validators.required],
        price: ['', Validators.required],
        murabahavalue: ['', Validators.required],
        tenor:['',Validators.required],
        tenorOtherUnit:['',Validators.required],
      });

      if (!this.isAddMode) {
          this.accountService.getById(this.id)
              .pipe(first())
              .subscribe(x => this.form.patchValue(x));
      }

     
  }

  ngOnDestroy(): void {
    this.dataService.setSaleConfirm({
      isConfirmed: false
    });
    this.dataService.setMurabahaConfirm({
      isConfirmed: false
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }


  fillForm(){
    if(this.newOrder){
      let clientName = '';
      if(this.isBank){
        clientName = this.banks?.find((item) => item.id===this.newOrder.customerId).bankname;
      }
      else{
        let clientName1 = this.accounts?.find((item) => item.id===this.newOrder.customerId);
        if(clientName1){
          clientName = clientName1.companyname;
        }else{
          this.isOrbit = true;
          clientName = this.orbit?.find((item) => item.id===this.newOrder.customerId).clientname;
        }
      }
      console.log(this.newOrder);
      const porfit  = Number(this.newOrder.differsaleprice)-Number(this.newOrder.costprice)
      this.form.patchValue({
        price: this.commify(Number(this.newOrder.costprice)),
        murabahavalue: this.commify(porfit),
        currency:this.newOrder.currency,
        clientName:clientName,
        clientName2:this.newOrder.clientName2,
        tenor:this.newOrder.orderTenor,
        // tenorOther:this.newOrder.tenorOther,
        tenor_other_unit:this.newOrder.tenorOtherUnit,
      });
    } 
  } 

  convertDateToMMDDYYYY(datestring:string){
    let dateArray= datestring.split('/');
    return dateArray[1]+'/'+dateArray[0]+'/'+dateArray[2];
  }

  //function is used to convert date object to dd/MM/YYYY formate
  convertDate(inputFormat) {
    function pad(s) { return (s < 10) ? '0' + s : s; }
    var d = new Date(inputFormat)
    return [pad(d.getDate()), pad(d.getMonth()+1), d.getFullYear()].join('/')
  }

  commify(n) {
    var parts = n.toString().split(".");

    const numberPart = parts[0];
    const decimalPart = parts[1];
    const thousands = /\B(?=(\d{3})+(?!\d))/g;

    return numberPart.replace(thousands, ",") + (decimalPart ? "." + decimalPart : "");
}

  onSubmit() {
      // stop here if form is invalid
      if (this.form.invalid) {
          return;
      }
    
    if(parseFloat(this.form.get('price')?.value.replace(/,/g, '')) > 0){
        let clientName=''
        if(this.isBank){
          clientName = this.banks?.find((item) => item.id===this.newOrder.customerId).bankname;
        }
        else{
          let clientName1 = this.accounts?.find((item) => item.id===this.newOrder.customerId);
          if(clientName1){
            clientName = clientName1.companyname;
          }else{
            this.isOrbit = true;
            clientName = this.orbit?.find((item) => item.id===this.newOrder.customerId).clientname;
          }
        }
      this.form.get('price').patchValue( parseFloat(this.form.get('price')?.value.replace(/,/g, '')).toString());
      this.form.get('murabahavalue').patchValue( parseFloat(this.form.get('murabahavalue')?.value.replace(/,/g, '')).toString());
      let payload = {
        customerId: this.newOrder.customerId,
        tradeID: this.newOrder.id,
        ownership: clientName,
        ownership2: this.form.value.clientName2,
        order_currency: this.newOrder.currency,
        order_amount:  this.form.get('price').value,
        murabaha_amount: this.form.get('murabahavalue').value,
        registerby:this.account.id,
        tenor:this.form.value.tenor,
        tenor_other_unit:this.form.value.tenorOtherUnit,
        brokerid:this.brokerid,  
    } 
    if(this.enableResubmit) {
      this.orderService.updateOrder(payload['tradeID'],payload).subscribe((res) =>{
        this.snackBar.openSnackBar('Order Re-submitted','Close','green-snackbar');
        this.submitted = true; 
      },(err) =>{
        this.snackBar.openSnackBar('something went wrong','Close','red-snackbar');
      })
    }
    else{
      this.orderService.registerOrder(payload).subscribe((res) =>{
        this.snackBar.openSnackBar(res['message'],'Close','green-snackbar');
        this.submitted = true; 
      },(err) =>{
        this.snackBar.openSnackBar('something went wrong','Close','red-snackbar');
      })
    }
  
    }
      else{
        this.snackBar.openSnackBar('Nominal Amount should be greater than 0','Close','red-snackbar');
      }
      
  }

  confirmOrder(){
    this.orderService.confirmOrderDmcc(this.newOrder.id).subscribe((res) =>{    
  
      if(res['message']){
        this.snackBar.openSnackBar(res['message'],'Close','red-snackbar');
        this.submitted = false;
        this.enableResubmit = true;
      }
      else{
        this.snackBar.openSnackBar('Order confirmed','Close','green-snackbar');
        if(this.isBank){
          this.router.navigate(['/admin/bankTrades'], { relativeTo: this.route });
        }
        else{
          if(this.isOrbit == true){
            this.router.navigate(['/orbitadmin/tradeRequest'], { relativeTo: this.route });
          }else{
            this.router.navigate(['/admin/tradeRequest'], { relativeTo: this.route });
          }
        }
      }
  
      
    },(err) =>{
      this.snackBar.openSnackBar('something went wrong','Close','red-snackbar');
    })  
  }

  confirmCertificate2(){
    this.showBuySell = true;
  }

  rejectOrder(){
    this.tradeService.cancelTrade(this.newOrder.id,'rejected during trade initiate',
    'Trade has been declined','Trade has been declined by admin').subscribe((res) =>{
      this.snackBar.openSnackBar('Trade rejected','Close','red-snackbar');
      if(this.isBank){
        this.router.navigate(['/admin/bankTrades'], { relativeTo: this.route });
      }
      else{
        if(this.isOrbit == true){
          this.router.navigate(['/orbitadmin/tradeRequest'], { relativeTo: this.route });
        }else{
          this.router.navigate(['/admin/tradeRequest'], { relativeTo: this.route });
        }
      }
    })
  }

  cancelOrder(){
    this.submitted = false;
   // this.router.navigate(['/newOrder'], { relativeTo: this.route });
  }

  getAllBanks(){
    this.bankService.getAllBanks().subscribe((banks) =>{
      this.banks = banks;
    })
  }

}
